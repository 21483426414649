import React, { useMemo, useRef, useState } from 'react';
import { Box, Button, Grid, InputAdornment, MenuItem, Paper, Select, Switch, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Table, FormControl, InputLabel } from '@mui/material';
import { DeleteIcon, EditIcon, EditIconn, NoDataImage, SearchNormal } from '../../assets';
import { FormIcon } from '../../assets';
import Breadcrumb from '../../Atoms/breadcrumb/breadcrumb';
import { useNavigate } from 'react-router';
import ZupotsuTable from '../../Atoms/zupotsu-table/zupotsuTable';
import { TablePaginationProps } from '../../Atoms/table/table-pagination';
import NoData from '../../error/NoData';
import IOSSwitch from '../../Atoms/buttons/IOSSwitch';
import { KeyboardArrowDownOutlined, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUpOutlined } from '@mui/icons-material';

import ReusableTable from '../../Molecules/table-management/ReusableTable';
import ActionButton from '../../Molecules/table-management/ActionButton';


type FormAttribute = {
  attribute_priority: number;
};

const ManageCategories: React.FC = () => {
  const [formState, setFormState] = useState<{ [key: string]: string }>({});
  const isSearched = useRef<boolean>(false);
  const headers = [ 'Category Name', 'Created on', 'Status', 'View'];
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // const handleSearchValue = async (value: string) => {
  //   isSearched.current = true;
  //   // setSearchValue(value);
  // };




  const columns: any = [
  
    { field: 'categoryname', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    { field: 'createdon', cellStyle: { height: "30px", fontSize: "14px", lineHeight: "21px", fontFamily: 'Inter', fontWeight: "500" } },
    {
      field: 'status',
      render: (value: any) => <IOSSwitch checkstatus={value} />,
      cellStyle: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start',paddingLeft:'40px'  }
    },
    {
      field: 'actions',
      render: (_: any, item: any) => (
        <ActionButton
          onEdit={() => { /* Add your edit functionality here */ }}
          onDelete={() => { alert(`Delete ${item.name}`) }}
          editIcon={EditIconn}
          deleteIcon={DeleteIcon}
          isReq={false}
        />
      ),
      cellStyle: { padding: 0, border: "0px solid transparent" }
    }

  ];




  const currentItems: any[] = [
    {
      id: 1,
      categoryname: 'L001',
      createdon: '1/1/2020',
      status: 'Role1',
      action: true,
    },
    {
      id: 2,
      categoryname: 'L002',
      createdon: '1/1/2020',
      status: 'Role1',
      action: true,
    },
    {
      id: 3,
      categoryname: 'L003',
      createdon: '1/1/2020',
      status: 'Role1',
      action: true,
    },
  ];

  const [selectedRole, setSelectedRole] = useState('');

  // const handleChangeRole = (event: any) => {
  //   setSelectedRole(event.target.value);
  // };

  const handleChangeTable = (event: any, item: any) => {
    const newRole = event.target.value;
    const newData = tableData.map((dataItem: any) =>
      dataItem.name === item.name ? { ...dataItem, role: newRole } : dataItem
    );
    setTableData(newData);
  };
  const [tableData, setTableData] = useState(currentItems);

  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const paginatedData = tableData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });;



  const navigation = useNavigate();


  const linkDetails = useMemo(() => [
    {
      label: 'Dashboard',
      url: '',
    },
    {
      label: 'Manage Categories',
      url: '/manage_categories',
    },
  ], []);



  const ErrorData = useMemo(
    () => ({
      img: NoDataImage,
      button: false,
      message: "No form listed"
    }),
    [
      // selectedCategory
    ]
  )







  const handleSort = (key: any) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    const sortedData = [...tableData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setTableData(sortedData);
  };

  const [hoveredRow, setHoveredRow] = useState(null);



  const onRowDoubleClick = () => {
    navigation("/previewform")
  };





  return (
    <Grid item xs={12} md={12} lg={12} sx={{ backgroundColor: 'rgb(250,250,250)',height:'90vh',overflowY:"scroll",overflowX:'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'center',
          padding: '5px',
          backgroundColor: 'rgb(250,250,250)',
        }}
      >


        <Grid xs={12} md={12} lg={12} width={"98%"} spacing={2} marginTop={"10px"}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              backgroundColor: '#FFF',
              paddingTop: "15px",
              paddingBottom: "15px",
              padding: "15px",
              alignItems: 'center',
            }}
          >
            <Breadcrumb
              linkDetails={linkDetails}
              underline="always"
              maxItems={3}
              itemBeforeCollapse={1}
              itemAfterCollapse={1}
              iconName="arrow_forward_ios_black_24dp"
              iconSize={20}
              iconLabel="Breadcrumb-Arrow-Right"
              iconStyle="regular"
              color="#333"
              textColor="#333"
            />
          </Box>
        </Grid>

        <div style={{ width: '98%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: "#FFF", paddingBottom: 10 }}>
          <div style={{ width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '10px', marginTop: '10px', marginBottom: '10px', backgroundColor: '#FFF' }}>
            {/* <div style={{ width: '20%' }}> */}
            <Typography
              sx={{
                fontFamily: 'BebasNeue',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '30px',
                textTransform: 'capitalize',
              }}
            >
              Manage Categories
            </Typography>
            {/* </div> */}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '20px',
                // width: '70%',
              }}
            >
              <div style={{
                padding: 0, margin: 0,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: "8px"
              }}>

                <TextField
                  // className={classes.searchInput}
                  placeholder="Search..."
                  sx={{
                    height: '40px',
                    '& .MuiFormControl-root': {
                      height: '40px',
                    },
                    '& .MuiTextField-root': {
                      height: '40px',
                    },
                    '& .MuiInputBase-root': {
                      height: '40px',
                    },
                    '& .MuiOutlinedInput-root': {
                      height: '40px',
                      border: "0px solid none"
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: "0px solid none"
                    },

                    '& .MuiOutlinedInput': {
                      border: "0px solid none"
                    },
                    width: '200px',
                    backgroundColor: 'rgba(242, 242, 242, 1)',
                    border: "0px solid none",
                    borderRadius: '4px'

                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={SearchNormal}
                          alt="Search"
                          style={{
                            // paddingBottom: '5px',
                            marginRight: '10px',
                            height: '20px',
                            cursor: 'pointer',
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>

            </div>
          </div>
          <div style={{ borderColor: '#FFF', borderStyle: 'solid', borderWidth: '0px', width: '95%' }}>

            {currentItems?.length > 0 ? (<>
 
              <ReusableTable
                columns={columns}
                tableData={tableData}
                setTableData={setTableData}
                headers={headers}
                search={''}
                sortingColumn={0}
                handleChange={handleChangeTable}
              />
            </>) : (
              <NoData ErrorData={ErrorData} />
            )}

          </div>
        </div>
      </Box>

    </Grid >
  );
};

export default ManageCategories;
